import './App.css';
import Content from "./components/Content";

function App() {
    return (
    <div><Content /></div>

    )
}

export default App;
